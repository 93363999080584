<template>
  <div class="hello">
      <div id='container'>
        <div id='DmanTip' class="position-item"  >
          <div id='date'>
            Fecha:  ?
          </div>
          <div id='tip'>
            <img src="@/assets/cryptoboard/INJ-dyFSRAB.png" />
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BAND',
  data () {
    return {
    }
  },
  methods: {
  },
}

</script>
